import styled from "styled-components";

export default styled.article`
h1, h2, h3, h4, h5, h6 {
  font-family: "Noto Sans KR", "AppleGothic", "나눔고딕", sans-serif;
}

p, li, blockquote {
  font-family: "Noto Serif KR", serif;
}

li {
  margin-left: 1rem;
  font-size: 0.95rem;
  line-height: 2rem;
  margin-bottom: 8px;
  @media screen and (max-width: 600px) {
    margin-left: 1.2rem;
    margin-right: 1rem;
    margin-bottom: 0px;
    line-height: 1.7rem;
  }
}

li p {
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px;
  font-size: 0.95rem;
  line-height: 2rem;
  margin-bottom: 8px;
  @media screen and (max-width: 600px) {
     margin-bottom: 0px;
     line-height: 1.7rem;
  }
}

ul, ol {
  margin: 2rem;
  font-size: 0.95rem;
  line-height: 2rem;
  padding: 0.4rem 5.5rem 0.4rem 1.5rem;
  overflow-x: hidden;
  box-sizing: border-box;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 600px) {
    padding: 0px;
  }
}

ul ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  list-style-type: circle;
}


h1 {
  margin-top: 2rem;
  font-size: 2rem;
  color: #343a40;
}

h2 {
  line-height: 1.45em;
  color: #495057;
  font-size: 1.6rem;
  margin-top: 4rem;
  margin-bottom: 1.4rem;
  box-sizing: border-box;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

h3 {
  line-height: 1.45em;
  font-size: 1.4rem;
  color: #495057;
  margin-top: 2rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4rem;
}

p {
  line-height: 1.9rem;
  margin: 1.6rem 0rem;
  text-indent: 0rem;
  overflow-x: hidden;
  box-sizing: border-box;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}


blockquote {
  font-style: italic;
  text-indent: 1rem;
  font-weight: normal;
  margin: 0rem;
  margin-bottom: 1rem;
  padding: 0rem;
  font-size: 1.2rem;
  padding: 1px 0px 1px 16px;
  border-left: 6px solid rgba(27, 28, 37, 0.2);
  @media screen and (max-width: 600px) {
     margin-left: -16px;
  }
}

pre code {
  overflow-x: auto;
  display: block;
  line-height: 1.4rem;
  font-size: 0.86rem;
  font-family: "Fira Code", monospace;
  color: #495057;
  @media screen and (max-width: 600px) {
     margin-left: -16px;
     margin-right: -16px;
     padding: 16px 16px;
  }
}

strong {
  font-weight: 900;
  color: #0366d6;
}

header p {
  margin-top: 4px;
  padding: 0px;
}

p img {
  width: 100%;
}

a {
  color: #0366d6;
  text-decoration: none;
  border-bottom: 1px solid #0366d6;
}

.anchor.before {
  border: none;
}

.toc {
  ul { list-style: none; }
  a {
    line-height: 32px;
    color: rgba(27, 28, 37, 0.8);
    text-decoration: none;
    border-bottom: 1px solid rgba(27, 28, 37, 0.2);
  }
  p { margin-bottom: 0px; }
  li { margin-bottom: 0px; }
  > ul { padding: 0; }
  > ul > li { margin-left: 0; }
}

figure {
  margin: 0;
  figcaption.gatsby-resp-image-figcaption {
    display: block;
    text-align: center;
    margin-top: 8px;
    color: rgba(27, 28, 37, 0.9);
    font-size: 12px;
    font-style: italic;
  }
}

`;
