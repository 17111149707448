import React from "react";
import { graphql } from "gatsby";

import Utterances from "../components/utterances";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Article from "../components/article";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    // const { previous, next } = this.props.pageContext;

    return (
      <Layout lang={post.frontmatter.lang} location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Article>
          <header>
            <h1 style={{ marginBottom: 0 }}>
              {post.frontmatter.title}
            </h1>
            <p style={{ display: `block` }}>
              {post.frontmatter.date}
            </p>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr />
          <footer></footer>
        </Article>
        <Utterances />
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
        stage
        tags
      }
    }
  }
`;
